import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/SviluppoApp.css";
import Lottie from "lottie-react";
import animationSpace from "../asset/Lottie_files/Animation -space.json";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SviluppoApp = () => {
  const navigate = useNavigate();

  const redirectManutenzioneOnClick = () => {
    navigate("/refactoring");
    window.scrollTo(0, 0);
  };

  const redirectGraphicOnClick = () => {
    navigate("/graphicDesignerPage");
    window.scrollTo(0, 0);
  };

  const redirectAppMobbileOnClick = () => {
    navigate("/appMobile");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Container className="conteiner-style ">
        <h2 className="text-center py-3 title-color">
          Sviluppo di applicazioni mobile{" "}
        </h2>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            <div className="text-white text-center  text-descriptionApp fs-5 d-flex flex-column justify-content-center align-items-center h-100">
              <p>
                Ti aiutiamo nella ideazione della tua app per IOS o Android e
                cercheremo di far si che il frontend ed il backend
                dell'applicazione si uniscano per creare una perfetta user
                experience performante!
              </p>{" "}
              <Button
                onClick={redirectAppMobbileOnClick}
                className="buttonLink-style fs-5"
              >
                Scopri di più
              </Button>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}>
            <Lottie
              data-aos="fade-up"
              className="animationSpace-style"
              animationData={animationSpace}
              loop={true}
            />
          </Col>
        </Row>
      </Container>
      <Container className="mt-4 mb-5">
        <Row>
          <Col lg={6} md={12} sm={12} xs={12} className="mb-2">
            <div
              className="d-flex flex-column justify-content-around align-items-center div-graphic m-auto p-2"
              data-aos="fade-up"
            >
              <h2 className="text-center fst-italic">Graphic designer</h2>
              <p className="text-center fw-bold">
                Soluzioni innovative, creative e personalizzate per la tua
                azienda!
              </p>
              <Button
                onClick={redirectGraphicOnClick}
                className="buttonLink-style fs-5"
              >
                Scopri di più
              </Button>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}>
            <div
              className="d-flex flex-column justify-content-around align-items-center div-assistence m-auto p-2 "
              data-aos="fade-down"
            >
              <h2 className="text-center fst-italic">
                Refactoring e manutenzione
              </h2>
              <p className="text-center fw-bold">
                Hai un sito web un pò vecchio stile e vuoi e vuoi migliorarlo?
                Ci pensiamo noi a renderlo moderno, dinamico e funzionale!
              </p>
              <Button
                onClick={redirectManutenzioneOnClick}
                className="buttonLink-style fs-5"
              >
                Scopri di più
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SviluppoApp;
