import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import iconaSitiWeb from "../../asset/web icon oro.png";
import "../../Style/Card.css";
import { CardGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function CardSitiWeb() {
  const navigate = useNavigate();

  const redirectSiteWebOnClick = () => {
    navigate("/pageSitiWeb");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={3} md={4} sm={12} xs={12}>
      <CardGroup>
        <Card className="text-center card-style shadow">
          <Card.Img className="img-card" variant="top" src={iconaSitiWeb} />
          <Card.Body className="d-flex flex-column justify-content-between">
            <Card.Title>Sviluppo di Siti Web</Card.Title>
            <Card.Text className="text-clamp">
              Sviluppo di siti web moderni e interattivi con una ottima utent
              Experience.Completamente responsive per un'ottima navigazione
              anche da mobile.
            </Card.Text>

            <Button onClick={redirectSiteWebOnClick} className="button-card">
              Scopri di più
            </Button>
          </Card.Body>
        </Card>
      </CardGroup>
    </Col>
  );
}

export default CardSitiWeb;
