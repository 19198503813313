import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logoPng from "../asset/logo senza sfondo.png";
import "../Style/Navbar.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function NavigationBar() {
  const navigate = useNavigate();

  const redirectHomeOnClick = () => {
    navigate("/");
  };
  const redirectContactOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };
  const redirectSiteWebOnClick = () => {
    navigate("/pageSitiWeb");
    window.scrollTo(0, 0);
  };
  const redirectCommerceOnClick = () => {
    navigate("/EcommercePage");
    window.scrollTo(0, 0);
  };
  const redirectGraphicOnClick = () => {
    navigate("/graphicDesignerPage");
    window.scrollTo(0, 0);
  };
  const redirectRefactoringOnClick = () => {
    navigate("/refactoring");
    window.scrollTo(0, 0);
  };
  const redirectAppClick = () => {
    navigate("/appMobile");
    window.scrollTo(0, 0);
  };

  return (
    <Navbar expand="lg" className="background-navbar  " fixed="top">
      <Container>
        <Navbar.Brand href="#">
          <img
            onClick={redirectHomeOnClick}
            className="logo-navbar"
            src={logoPng}
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 d-flex gap-3 fw-bold"
            navbarScroll
          >
            <Nav.Link className="linkNav" href="/">
              Home
            </Nav.Link>

            <Nav.Link onClick={redirectSiteWebOnClick} className="linkNav">
              Siti Web
            </Nav.Link>

            <Nav.Link onClick={redirectCommerceOnClick} className="linkNav">
              E-Commerce
            </Nav.Link>

            <Nav.Link onClick={redirectAppClick} className="linkNav">
              App Mobile
            </Nav.Link>

            <Nav.Link onClick={redirectGraphicOnClick} className="linkNav">
              Graphic Designer
            </Nav.Link>

            <Nav.Link onClick={redirectRefactoringOnClick} className="linkNav">
              Refactoring
            </Nav.Link>

            <Nav.Link onClick={redirectContactOnClick} className="linkNav">
              Contatti
            </Nav.Link>
            {/* <NavDropdown
              className="customNavDropdown style-dropdown"
              title="I Nostri Servizi"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="#action4">
                Creazione Siti Web
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Creazione di E-Commerce
              </NavDropdown.Item>
              <NavDropdown.Item href="#action6">
                Creazione di App personalizzate
              </NavDropdown.Item>
              <NavDropdown.Item href="#action7">
                Digital Marketing
              </NavDropdown.Item>
              <NavDropdown.Item href="/graphicDesignerPage">
                Graphic Designer
              </NavDropdown.Item>
              <NavDropdown.Item href="#action9">
                Assistenza e Manutenzione
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
