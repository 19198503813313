import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import iconaSocial from "../../asset/marketing icon oro.png";
import "../../Style/Card.css";
import { Col } from "react-bootstrap";

function CardsMarketing() {
  return (
    <Col lg={3} md={4} sm={12} xs={12}>
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={iconaSocial} />
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Title>Digital Marketing</Card.Title>
          <Card.Text className="text-clamp">
            Gestione di Campagne Pubblicitarie sui Social e su Google per
            aumentare la visibilità della propria azienda.
          </Card.Text>
          <Button className="button-card">Prossimamente..</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CardsMarketing;
