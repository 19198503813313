import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import iconaGraphic from "../../asset/graphic icon oro.png";
import "../../Style/Card.css";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CardGraphic() {
  const navigate = useNavigate();

  const redirectGraphicOnClick = () => {
    navigate("/graphicDesignerPage");
    window.scrollTo(0, 0);
  };

  return (
    <Col lg={3} md={4} sm={12} xs={12}>
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={iconaGraphic} />
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Title>Graphic Designer</Card.Title>
          <Card.Text className="text-clamp">
            Dai uno stile unico alla tua azienda con il contributo della nostra
            graphic designer! Progetta insieme a noi loghi,volantini, biglietti
            da visita personalizzati in ogni dettaglio e molto altro ancora.
          </Card.Text>
          <Button onClick={redirectGraphicOnClick} className="button-card">
            Scopri di più
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CardGraphic;
