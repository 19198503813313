import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import CallToActionWhatsApp from "../Components/CallToActionWhatsApp";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import ContactForm from "../Components/ContactForm";
import eCommerceImg from "../asset/background-eCommerce.png";
import backgroundCommerce from "../asset/background-eCommercePage.png";
import Aos from "aos";
import "aos/dist/aos.css";

import Lottie from "lottie-react";
import animationEcommerce from "../asset/Lottie_files/Animation - e-commerce.json";
import "../Style/pageECommerce.css";
import { Helmet } from "react-helmet";

const PageECommerce = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  const commerceStyle = {
    backgroundImage: `url(${backgroundCommerce})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Sviluppo di e-commerce</title>
        <meta
          name="description"
          content="La All Coding silupperà per te un e-commerce unico e personalizzato per la tua azienda per vendere i tuoi prodotti online ed incrementare il tuo businnes"
        />
      </Helmet>
      <CallToActionWhatsApp />
      <SfondoAnimatoOnde />

      <Container fluid className="w-100 my-5">
        <h1 className="text-center my-4 fs-1 fw-bold  ">
          Sviluppo di e-commerce
        </h1>

        <img className="w-100 " src={eCommerceImg} alt="img developer" />
      </Container>

      <Container className="my-5 shadow rounded ">
        <h2 className="text-center mt-5 mb-3 pt-2 fw-bold fst-italic fs-2 color-title">
          Vuoi vendere i tuoi prodotti online?
        </h2>
        <p className="text-center p-4 fs-5">
          Ci pensiamo noi! Progettiamo il sito e-commerce più adatto alle tue
          esigenze. Noi della All Coding amiamo creare progetti vincenti e per
          farlo poniamo grande attenzione a capire la tua attività e i tuoi
          obiettivi.
          <hr />
          <span className="fw-bold ms-1">
            Il mercato delle vendite online è in forte aumento, per questo è
            arrivato il momento di espandere la tua clientela a livello globale.
          </span>{" "}
        </p>
      </Container>
      <Container className="my-5 pt-5 shadow rounded">
        <h2 className="text-center fs-1 fw-bold fst-italic">
          Sviluppiamo il negozio online adatto al tuo business{" "}
        </h2>
        <Container>
          <Row className="my-5">
            <Col
              data-aos="fade-up"
              className="d-flex justify-content-center align-items-center mb-4"
              lg={4}
              md={12}
              sm={12}
              xs={12}
            >
              <Lottie
                data-aos="fade-up"
                animationData={animationEcommerce}
                loop={true}
              />
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center"
              lg={8}
              md={12}
              sm={12}
              xs={12}
            >
              <ul className="ul-style ">
                <li className="mb-4">
                  Realizziamo il tuo e-commerce interamente in codice seguendo
                  il progetto ideato insieme in fase di consulenza
                </li>
                <li className="mb-4">
                  Avrai un sito di vendita online unico, originale e interamente
                  customizzato per te
                </li>
                <li className="mb-4">
                  Studiamo il graphic design adatto alla tua azienda e ai tuoi
                  prodotti
                </li>
                <li className="mb-4">Scegliamo i canali di pagamento</li>
                <li className="mb-4">
                  Renderemo il lato di back office ottimizzato per la tua
                  gestione del sito in autonomia
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>

      <ContactForm />
      <Footer />
    </>
  );
};

export default PageECommerce;
