import React, { useState, useEffect } from "react";
import "../Style/snowFall.css"; // Assicurati di avere un file CSS collegato per gli stili

const Snowfall = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    const generateSnowflake = () => {
      return {
        left: Math.random() * 100 + "vw",
        animationDuration: Math.random() * 3 + 2 + "s",
        animationDelay: Math.random() + "s",
      };
    };

    const createSnowfall = () => {
      const flakes = Array.from({ length: 50 }, () => generateSnowflake());
      setSnowflakes(flakes);
    };

    createSnowfall();
  }, []);

  return (
    <div className="snowfall-container">
      {snowflakes.map((flake, index) => (
        <div
          key={index}
          className="snowflake"
          style={{
            left: flake.left,
            animationDuration: flake.animationDuration,
            animationDelay: flake.animationDelay,
          }}
        />
      ))}
    </div>
  );
};

export default Snowfall;
