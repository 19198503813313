import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../Style/ContactForm.css";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

export const ContactForm = () => {
  const onChange = () => {};

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uzh0wgu",
        "template_ah9op94",
        form.current,
        "esHEEe2jlXUSI3dG6"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success(
            "Richiesta inoltrata con successo! Sarai ricontattata/o al piu presto dal nostro team. Controlla la tua email nelle prossime 24 ore",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
        },

        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="col-style" lg={12}>
            <form
              className="form-style animated-gradient d-flex flex-column p-5 "
              ref={form}
              onSubmit={sendEmail}
            >
              <h3 className="text-center text-white">
                Contattaci per un preventivo o una consulenza gratuita
              </h3>
              <p className="text-center text-white">
                Per qualsiasi informazione lasciaci i tuoi contatti ti
                rincotattiamo noi! <hr />
                Nel messaggio spiegaci brevemente ciò di cui hai bisogno
              </p>
              <label>Nome e Cognome</label>
              <input className="input-style" type="text" name="user_name" />
              <label>Email</label>
              <input className="input-style" type="email" name="user_email" />
              <label>Numero di telefono</label>
              <input className="input-style" type="number" name="user_number" />
              <label>Messaggio</label>
              <textarea className="input-style" name="message" />
              <div className=" d-flex justify-content-center my-3">
                <ReCAPTCHA
                  sitekey="6LcjihYpAAAAAAEMxd5gWqUfk9Vyb2J0HHxCcF0z"
                  onChange={onChange}
                  className=" "
                />
              </div>

              <input className="button-form" type="submit" value="Invia" />
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactForm;

/* import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const recaptchaRef = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();

    const emailService = 'YOUR_EMAIL_SERVICE_ID';
    const template = 'YOUR_TEMPLATE_ID';
    const userID = 'YOUR_USER_ID';

    const templateParams = {
      user_name: formData.name,
      user_email: formData.email,
      message: formData.message,
    };

    emailjs.send(emailService, template, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully!', response);
        // Puoi effettuare altre azioni come la pulizia del modulo o il reindirizzamento
      })
      .catch((error) => {
        console.error('Email send failed:', error);
      });

    recaptchaRef.current.reset();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
        />
        <ReCAPTCHA
         grecaptcha={Response}
          ref={recaptchaRef}
          sitekey="YOUR_RECAPTCHA_SITE_KEY"
          size="invisible"
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;
 */
