import React from "react";
import NavigationBar from "../Components/NavigationBar";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import BoardCards from "../Components/BoardCards";
import MetodoDiLavoro from "../Components/MetodoDiLavoro";
import PresentazioneTeam from "../Components/PresentazioneTeam";
import Tecnologie from "../Components/Tecnologie";
import SviluppoApp from "../Components/SviluppoApp";
import ContactForm from "../Components/ContactForm";
import Footer from "../Components/Footer";
import CallToActionWhatsApp from "../Components/CallToActionWhatsApp";
import Intro from "../Components/Intro";

const Homepage = () => {
  return (
    <>
      <NavigationBar />
      <Intro />
      <CallToActionWhatsApp />
      <SfondoAnimatoOnde />
      <BoardCards />
      {<MetodoDiLavoro />}
      <PresentazioneTeam />
      <Tecnologie />
      <SviluppoApp />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Homepage;
