import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SitiWebImg from "../asset/background-Siti web.png";
import NavigationBar from "../Components/NavigationBar";
import CallToActionWhatsApp from "../Components/CallToActionWhatsApp";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import Footer from "../Components/Footer";
import Lottie from "lottie-react";
import animationSiteDevelop from "../asset/Lottie_files/Animation - SitiWebPage.json";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSitiWeb.css";
import SitiSenzaPensieri from "../asset/sitoSenzaPensieri.png";
import ContactForm from "../Components/ContactForm";
import { Helmet } from "react-helmet";

const PageSitiWeb = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Sviluppo di siti web</title>
        <meta
          name="description"
          content="Il team della All Coding sviluppa siti web moderni, sicuri e funzionali in base alle vostre esigenze e vi aiuterà nella gestione per tenerlo sempre aggiornato "
        />
      </Helmet>
      <CallToActionWhatsApp />
      <SfondoAnimatoOnde />
      <Container fluid>
        <h1 className="text-center my-4 fs-1 fw-bold ">Sviluppo di siti Web</h1>

        <img className="w-100" src={SitiWebImg} alt="img developer" />
      </Container>

      <Container className="my-5">
        <h2 className="text-center mt-5 mb-3 pt-2 fw-bold fst-italic fs-2">
          Perchè e importante avere un sito web?
        </h2>
        <p className="text-center p-4 fs-5">
          Un sito web è un investimento strategico che va oltre la semplice
          presenza online. È il cuore pulsante della tua identità digitale,
          apportando vantaggi tangibili come
          <span className="fw-bold ms-1">
            rendere più visibile la tua azienda, costruzione di fiducia ed
            espansione della clientela .
          </span>{" "}
          <hr />
          In un mondo sempre più digitalizzato, un sito web è il tuo biglietto
          da visita per il successo aziendale a lungo termine.
        </p>
      </Container>
      <Container className="mt-5">
        <Row className="shadow rounded">
          <Col
            className="my-auto "
            data-aos="fade-up"
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <Lottie animationData={animationSiteDevelop} loop={true} />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <h3 className="text-center my-5 fw-bold fst-italic fs-1">
              I vantaggi di svilupparlo con noi
            </h3>
            <ul className="fs-5">
              <li className="mb-3">
                Massima trasparenza con il cliente per password del dominio e
                gestione
              </li>
              <li className="mb-3">
                Avrai 1 anno di gestione e manutenzione gratis
              </li>
              <li className="mb-3">
                Avrai un sito web che si adatterà ad ogni tipo di schermo
              </li>
              <li className="mb-3">
                Avrai un servizio di grafica personalizzata grazie alla nostra
                graphic designer
              </li>
              <li className="mb-3">
                Potrai effettuare un upgrade per farlo diventare un e-commerce o
                una web app in un secondo momento senza difficoltà
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="text-center my-5">
        <h2 className="fs-1 fw-bold fst-italic my-5">
          Sito web senza pensieri!!
        </h2>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex  align-items-center justify-content-center "
          >
            <img
              data-aos="fade-up"
              className="rounded-circle w-100 shadow my-auto"
              src={SitiSenzaPensieri}
              alt="Siti web senza pensieri"
            />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex  align-items-center justify-content-center "
          >
            <p className="p-webNopensieri p-4 fs-5">
              <span className="fs-4 fw-bold">
                Non hai tempo di gestire il tuo sito web?
              </span>
              <hr />
              Nessun problema! La All Coding farà tutto il necessario per
              renderlo sempre aggiornato e con una SEO performante. Ci
              occuperemo di associarlo alla tua indicizzazione Google, ai tuoi
              social e lo aggiorneremo secondo le tue esigenze.
            </p>
          </Col>
        </Row>
      </Container>

      <ContactForm />
      <Footer />
    </>
  );
};

export default PageSitiWeb;
