import React from "react";
import { Button, Container } from "react-bootstrap";
import LogoPngNoScritta from "../asset/imgLogo-senza_sfondo.png";
import "../Style/intro.css";
import background from "../asset/background-intro.png";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const introStyle = {
    backgroundImage: `url(${background})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const navigate = useNavigate();

  const redirectContactOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };

  return (
    <Container fluid style={introStyle}>
      <div className="mt-3 d-flex flex-column justify-content-between align-items-center color-title text-center fade-in ">
        <img className="logo-welcome" src={LogoPngNoScritta} alt="logo" />
        <h1 className="fs-1 fw-bold fst-italic">All Coding</h1>
        <hr />
        <h4 className="fs-2 fw-bold mb-2">Il tuo sito web senza pensieri!!</h4>
        <hr className="color-title-hr" />
        <p className="fs-4">
          Sviluppiamo, gestiamo e aiutiamo i nostri clienti ad avere sempre un
          sito web performante e aggiornato{" "}
        </p>
        <Button
          onClick={redirectContactOnClick}
          className="button-presentation fs-3"
        >
          Contattaci
        </Button>
      </div>
    </Container>
  );
};

export default Intro;
