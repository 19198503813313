import React from "react";
import "../Style/whatsappAction.css";
import { BsWhatsapp } from "react-icons/bs";
import Lottie from "lottie-react";
import animationWhatsapp from "../asset/Lottie_files/Animation - whatsapp.json";

function CallToActionWhatsApp() {
  const phoneNumber = "+393293457714"; // Sostituisci con il tuo numero di telefono aziendale
  const message = "Ciao, vorrei mettermi in contatto con voi.";

  const handleWhatsAppClick = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappURL;
  };

  return (
    <div>
      <Lottie
        animationData={animationWhatsapp}
        loop={true}
        onClick={handleWhatsAppClick}
        className="button-whatsapp"
      />
    </div>
  );
}

export default CallToActionWhatsApp;
