import React, { useEffect } from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import appImg from "../asset/background-appIntro (1920 x 500 px).png";
import NavigationBar from "../Components/NavigationBar";
import CallToActionWhatsApp from "../Components/CallToActionWhatsApp";
import SfondoAnimatoOnde from "../Components/SfondoAnimatoOnde";
import ContactForm from "../Components/ContactForm";
import Footer from "../Components/Footer";
import "../Style/pageApp.css";

import iconaAppMobile from "../asset/icona-appMobile.png";
import iconaWebApp from "../asset/icona web-app.png";
import Lottie from "lottie-react";
import animationBackend from "../asset/Lottie_files/Animation-backend.json";

const PageAppMobile = () => {
  return (
    <>
      <NavigationBar />
      <CallToActionWhatsApp />
      <SfondoAnimatoOnde />

      <Container fluid>
        <h1 className="text-center fw-bold my-4">
          {" "}
          Sviluppo di app mobile e web app
        </h1>
        <img className="w-100 shadow mb-5" src={appImg} alt="img app mobile" />
      </Container>
      <Container>
        <h2 className="text-center fw-bold fs-1">
          {" "}
          Rendi la tua attività più smart
        </h2>
        <p className=" text-center  p-2 fs-5">
          Sviluppiamo app mobile e web app personalizzate per la tua azienda.
          Noi della All Coding siamo una software house ciò significa che
          sviluppiamo da zero la tua applicazione in base alle tue esigenze per
          l'utilizzo.
        </p>
      </Container>
      <Container className="my-5 shadow rounded text-center ">
        <h3 className="text-center fw-bold fs-2 pt-3">
          L'importanza del backend in un'applicazione
        </h3>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Lottie
              className="w-100"
              animationData={animationBackend}
              loop={true}
            />
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <p className="p-2 line-height2 fs-5">
              È essenziale che il backend comunichi perfettamente con il
              frontend ed esegua le richieste desiderate senza problemi. Ciò
              richiede molta esperienza e una visione globale su tutti i sistemi
              e le applicazioni. Il lato backend rappresenta il cervello di
              un'applicazione. Funge da base dell'app, controllando e gestendo
              tutto sul server. Noi della All Coding lavoriamo con diverse
              tecnologie come PHP, Node.js, Express, Redux Toolkit, Python, SQL
              e MongoDB. <hr />
              Ci concentriamo interamente sull'architettura dietro
              l'applicazione che rimane nascosta per l'utente. Costruiamo
              sistemi back-end per web app robusti, sicuri e in grado di reagire
              prontamente e con dinamicità ai guasti.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <h2 className=" text-center fst-italic fw-bold">
          Le nostre soluzioni per l'innovazione tecnologica della tua azienda
        </h2>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="text-center">
            <img className=" w-100 p-4" src={iconaAppMobile} alt="" />
            <h5 className="text-center fst-bold fs-2 fst-italic">
              App mobile per IOS e Android
            </h5>
            <p className="line-height2 p-3 fs-5 shadow rounded  my-4">
              Partiamo a sviluppare la tua applicazione per una navigazione
              ottimizzata da dispositivi mobile. Le app per eccellenza sono
              quelle che abbiamo nel nostro smartphone. Premiamo l’icona ed
              entra in funzione l’app con tutte le sue caratteristiche. A noi ci
              piace restare sempre aggiornati e proporre al cliente soluzioni
              all’avanguardia, sicure ed innovative. <hr />
              Oltre allo sviluppo ci prendiamo cura della tua app curandone la
              manutenzione, la risoluzione dei bugs e gli aggiornamenti.
            </p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="text-center">
            <img className="w-100 p-4" src={iconaWebApp} alt="" />
            <h5 className="text-center fst-bold fs-2 fst-italic">Web app</h5>
            <p className="line-height2 p-3 fs-5 shadow rounded  my-4">
              Le web app funzionano nel browser e l'utente non ha bisogno di
              installarla o scaricarla. Noi della All Coding svilupperemo per te
              una web app completamente personalizzata con login dell'utente,
              geolocalizzazzione, chat tra utenti o chat per l'assistenza,
              autenticazione dell'utente e tanto altro... Potrai avere il tuo
              lato di back office da amministratore per gestire in modo autonomo
              la tua web app. <hr /> Sarà ottimizzata per la navigazione da
              qualsiasi tipo di schermo e verrà sviluppata per seguire a pieno
              le tue esigenze ed aiutare i tuoi clienti.
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <span className="fw-bold fst-italic">
                      Che cos'è una web app?
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    Mai sentito parlare di web app o web application? Anche se
                    non ne hai mai sentito parlare, sicuramente ti sarai trovato
                    ad utilizzarla. La web app è un software applicativo che si
                    utilizza attraverso il web. Quindi per utilizzarla ti
                    servirà semplicemente accedere al tuo browser (Chrome,
                    Safari, Edge) e poi inserire l’indirizzo internet che vuoi
                    raggiungere. Da quello che ti abbiamo spiegato fin qui
                    potrebbe sembrare come un sito. In realtà la modalità di
                    accesso è la stessa ma quello che fa la differenza è che una
                    web app esegue un programma.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </p>
          </Col>
        </Row>
      </Container>

      <ContactForm />
      <Footer />
    </>
  );
};

export default PageAppMobile;
