import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import iconaApp from "../../asset/app icon oro.png";
import "../../Style/Card.css";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CardApp() {
  const navigate = useNavigate();

  const redirectAppMobileOnClick = () => {
    navigate("/appMobile");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={3} md={4} sm={12} xs={12}>
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={iconaApp} />
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Title>Applicazioni Mobile</Card.Title>
          <Card.Text className="text-clamp">
            Sviluppo di Web App dedicate per aziende o privati, ottimizzate per
            la navigazione da mobile.
          </Card.Text>
          <Button onClick={redirectAppMobileOnClick} className="button-card">
            Scopri di più
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CardApp;
