import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import iconaCommerce from "../../asset/icona e-commerce oro.png";
import "../../Style/Card.css";
import { CardGroup, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CommerceCard() {
  const navigate = useNavigate();

  const redirectEcommerceOnClick = () => {
    navigate("/EcommercePage");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={3} md={4} sm={12} xs={12}>
      <CardGroup>
        <Card className="text-center card-style shadow">
          <Card.Img className="img-card" variant="top" src={iconaCommerce} />
          <Card.Body className="d-flex flex-column justify-content-between">
            <Card.Title>E-Commerce</Card.Title>
            <Card.Text className="text-clamp">
              Sviluppo di E-Commerce per qualsiasi attività o businness.
              Perfetti per espandere la propria attività online con potenziali
              clienti in tutto il mondo!
            </Card.Text>
            <Button onClick={redirectEcommerceOnClick} className="button-card">
              Scopri di più
            </Button>
          </Card.Body>
        </Card>
      </CardGroup>
    </Col>
  );
}

export default CommerceCard;
