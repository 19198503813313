import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import iconaManutenzione from "../../asset/refactoring icon oro.png";
import "../../Style/Card.css";
import { CardGroup, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function CardManutenzione() {
  const navigate = useNavigate();

  const redirectManutenzioneOnClick = () => {
    navigate("/refactoring");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={3} md={4} sm={12} xs={12}>
      <CardGroup>
        <Card className="text-center card-style shadow">
          <Card.Img
            className="img-card"
            variant="top"
            src={iconaManutenzione}
          />
          <Card.Body className="d-flex flex-column justify-content-between">
            <Card.Title>Refactoring e Assistenza </Card.Title>
            <Card.Text className="text-clamp">
              Hai un sito web vecchio e vuoi dargli un tocco di stile? Ci
              pensiamo noi! Offriamo assistenza per modernizzare siti web e
              renderli più accattivanti!
            </Card.Text>
            <Button
              onClick={redirectManutenzioneOnClick}
              className="button-card"
            >
              Scopri di più
            </Button>
          </Card.Body>
        </Card>
      </CardGroup>
    </Col>
  );
}

export default CardManutenzione;
