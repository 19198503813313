import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logoPng from "../asset/logo senza sfondo.png";
import "../Style/footerStyle.css";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {" "}
      <section className="text-center d-flex justify-content-center align-items-center gap-5 my-5 color-section">
        <span className="fs-4 fw-bold fst-italic">Seguici sui social </span>
        <div className=" d-flex justify-content-center align-items-center gap-3">
          <Link to={"https://www.instagram.com/all_coding__/"}>
            <GrInstagram className="fs-3 style-socialIcon" />
          </Link>
          <Link
            to={
              "https://www.facebook.com/profile.php?id=61555362477461&locale=it_IT"
            }
          >
            <BsFacebook className="fs-3 style-socialIcon" />
          </Link>
          <AiFillLinkedin className="fs-2 style-socialIcon" />
        </div>
      </section>
      <Container fluid className="mt-5 footer-background fw-bold p-2">
        <Row>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="d-flex flex-column justify-content-center w-100 h-100">
              <img className="logo-style " src={logoPng} alt="" />
              <p className=" text-center">
                Dai più valore alla tua azienda, falla conoscere online!
              </p>
            </div>
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content- align-items-center mb-3"
          >
            <h5 className="mb-3">Contatti</h5>
            <div className="d-flex align-items-center mb-3">
              <BsPhone className="fs-4 me-2" /> <span> +39 3293457714</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <BsWhatsapp className="fs-4 me-2" /> <span> +39 3293457714</span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <AiOutlineMail className="fs-4 me-2" />{" "}
              <span> allcodingweb@gmail.com</span>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12} xs={12} className="">
            <h5 className="mb-3">I nostri servizi</h5>
            <ul className="ul-style">
              <li>Sviluppo siti web</li>
              <li>Sviluppo di e-commerce</li>
              <li>Sviluppo di applicazioni mobile</li>
              <li>Graphic designer</li>
              <li>Refactoring e manutenzione</li>
              <li>Digital Marketing</li>
              <li>Consulenza gratuita</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="text-center bg-black text-white">
        Tutti i diritti sono riservati ad All Coding di Christian Graziano ©
        2023{" "}
        <span>
          <a
            href="https://www.iubenda.com/privacy-policy/74178899"
            class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
        </span>
      </div>
    </>
  );
};

export default Footer;
