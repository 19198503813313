import React, { useEffect, useRef, useState } from "react";
import MetodJobPng from "../asset/metodo di lavoro png.png";
import MobileMetodJob from "../asset/metodo di lavoro mobile.png";
import "../Style/metodoLavoro.css";
import Aos from "aos";
import "aos/dist/aos.css";

const MetodoDiLavoro = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <h2 className="text-center mt-5 fs-1 fw-bold">
        Il Nostro Metodo Di Lavoro
      </h2>
      <div
        data-aos="fade-up"
        className="d-flex align-items-center justify-content-center div_hidden"
      >
        <img
          className="img-dimension imgLg-dNone "
          src={MetodJobPng}
          alt="metod-job-company"
        />

        <img
          className="img-dimension imgSm-dNone mb-5"
          src={MobileMetodJob}
          alt="metod-job-company"
        />
      </div>
    </>
  );
};

export default MetodoDiLavoro;
