import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import animationTeam from "../asset/Lottie_files/Animation_presentationTeam.json";
import "../Style/chiSiamo.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import backgroundTeam from "../asset/backgroundTeam.png";

const PresentazioneTeam = () => {
  const PresentationStyle = {
    backgroundImage: `url(${backgroundTeam})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const navigate = useNavigate();

  const redirectContactOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container className="p-4 shadow" fluid style={PresentationStyle}>
        <Row>
          <Col
            className="my-auto"
            data-aos="fade-up"
            lg={6}
            md={12}
            sm={12}
            xs={12}
          ></Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="shadow rounded bg-white"
          >
            <div className="text-center my-auto p-3 ">
              <h2 className=" mb-lg-5 fs-1 color-title mt-1 fw-bold">
                Chi è la All Coding?
              </h2>
              <p className=" text-description fs-5 fw-bold">
                Siamo un team di professionisti che ti resta accanto per far
                crescere la tua attività sfruttando la visibilità della rete.
                Lavoriamo con passione, dedizione e grande impegno perchè per
                noi il risultato migliore è la soddisfazione del cliente. Ci
                occupiamo della progettazione e sviluppo di web app, mobile app,
                siti internet, e-commerce e del design grafico.
                <hr />
                <span className="fs-2">
                  Sei pronto per potenziare il tuo business?
                </span>
              </p>

              <Button
                onClick={redirectContactOnClick}
                className="button-presentation fs-5"
              >
                Contattaci
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PresentazioneTeam;
