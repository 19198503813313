import React from "react";
import { CardGroup, Container, Row } from "react-bootstrap";
import CardSitiWeb from "./Cards/CardSitiWeb";
import CommerceCard from "./Cards/CommerceCard";
import CardApp from "./Cards/CardApp";
import CardsMarketing from "./Cards/CardsMarketing";
import CardManutenzione from "./Cards/CardManutenzione";
import CardGraphic from "./Cards/CardGraphic";

const BoardCards = () => {
  return (
    <Container className="mt-5 ">
      <Row className="d-flex justify-content-center align-items-top">
        <CardSitiWeb />
        <CommerceCard />
        <CardApp />
        <CardGraphic />
        <CardManutenzione />
        <CardsMarketing />
      </Row>
    </Container>
  );
};

export default BoardCards;
