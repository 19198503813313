import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import ErrorPage from "./Pages/ErrorPage";
import PageSitiWeb from "./Pages/PageSitiWeb";
import Contatti from "./Pages/Contatti";
import GraphicDesignerPage from "./Pages/GraphicDesignerPage";
import PageECommerce from "./Pages/PageECommerce";
import { Helmet } from "react-helmet";
import PageManutenzione from "./Pages/PageManutenzione";
import PageAppMobile from "./Pages/PageAppMobile";

const App = () => {
  return (
    <Router>
      <Helmet>
        <title>All Coding</title>
        <meta
          name="description"
          content="Noi della All Coding ci occupiamo dello sviluppo di siti web, sviluppo di e-commerce, sviluppo di app mobile, graphic designer, consulenza gratuita"
        />
        <meta
          name="keywords"
          content="Siti web, E-commerce, Web app, App mobile, Graphic designer"
        />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/pageSitiWeb" element={<PageSitiWeb />} />
        <Route path="/contatti" element={<Contatti />} />
        <Route path="/graphicDesignerPage" element={<GraphicDesignerPage />} />
        <Route path="/EcommercePage" element={<PageECommerce />} />
        <Route path="/refactoring" element={<PageManutenzione />} />
        <Route path="/appMobile" element={<PageAppMobile />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
