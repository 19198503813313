import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import "../Style/tecnologie.css";
import screenshot from "../asset/sitiWebHome_responsive 2.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { GiPencilBrush } from "react-icons/gi";
import { GiArmorUpgrade } from "react-icons/gi";
import { TbWorldWww } from "react-icons/tb";
import { MdSettingsSuggest } from "react-icons/md";

import Slider from "react-infinite-logo-slider";
import logoBootstrap from "../asset/loghi-slider/bootstrap_logo.png";
import logoReact from "../asset/loghi-slider/react_logo.png";
import logoRedux from "../asset/loghi-slider/Redux_logo.png";
import logoNode from "../asset/loghi-slider/node_logo.png";
import logoMongoDB from "../asset/loghi-slider/mongoDB_logo.png";
import logoJs from "../asset/loghi-slider/js_logo.png";
import logoHtml from "../asset/loghi-slider/html_logo.png";
import logoCss from "../asset/loghi-slider/css_logo.png";
import { useNavigate } from "react-router-dom";

const Tecnologie = () => {
  const navigate = useNavigate();

  const redirectSiteWebPageOnClick = () => {
    navigate("/pageSitiWeb");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container className="styleContainer my-5 shadow p-2">
        <div className="text-center">
          <h2 className="pt-5 fs-1 mb-5 fst-italic">
            Sviluppo di siti web ed e-commerce{" "}
          </h2>
        </div>
        <div>
          <img
            data-aos="fade-up"
            data-aos-duration="3000"
            className="img-progetto"
            src={screenshot}
            alt="screenshot progetto"
          />
        </div>
        <div className="d-flex flex-column ps-2 mt-5 gap-3 fw-bold">
          <span className="mb-2 d-flex align-items-center gap-2 ">
            <MdOutlineMobileFriendly className="size-icon" /> I nostri siti web
            sono responsive su qualsiasi tipo di schermo
          </span>
          <span className="mb-2 d-flex align-items-center gap-2">
            <GiArmorUpgrade className="size-icon" />
            Completamente personalizzabili e versatili per eventuali modifiche o
            aggiunte di componenti
          </span>
          <span className="mb-2 d-flex align-items-center gap-2">
            <GiPencilBrush className="size-icon" />
            Avrai un servizio dedicato per personalizzazione grafica grazie alla
            nostra graphic designer
          </span>
          <span className="mb-2 d-flex align-items-center gap-2">
            {" "}
            <TbWorldWww className="size-icon" /> Gestiremo noi il tuo dominio e
            hosting
          </span>
          <span className="mb-2 d-flex align-items-center gap-2">
            {" "}
            <MdSettingsSuggest className="size-icon " />
            Avrai supporto e assistenza per sempre
          </span>
        </div>
        <div className="my-4 d-flex justify-content-center align-items-center">
          <Button
            onClick={redirectSiteWebPageOnClick}
            className="button-presentation fs-5"
          >
            Maggiori informazioni
          </Button>
        </div>
      </Container>
      <Container className="styleContainer mb-5 shadow">
        <h3 className="text-center py-4 fs-2 fst-italic">
          Le tecnologie che utilizziamo
        </h3>

        <Slider width="200px" duration={30} pauseOnHover={true}>
          <Slider.Slide>
            <div className="text-center stack_icon_hover px-5 mx-5">
              <img
                src={logoBootstrap}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img src={logoReact} alt="any" className="w-36 stack_icon_img" />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img src={logoRedux} alt="any" className="w-36 stack_icon_img" />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img src={logoNode} alt="any" className="w-36 stack_icon_img" />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img
                src={logoMongoDB}
                alt="any"
                className="w-36 stack_icon_img"
              />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img src={logoJs} alt="any" className="w-36 stack_icon_img" />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img src={logoHtml} alt="any" className="w-36 stack_icon_img" />
            </div>
          </Slider.Slide>
          <Slider.Slide>
            <div className="text-center stack_icon px-5 mx-5">
              <img src={logoCss} alt="any" className="w-36 stack_icon_img" />
            </div>
          </Slider.Slide>
        </Slider>
      </Container>
    </>
  );
};

export default Tecnologie;
